<template>
  <RadioToggle
    class="d-inline-block"
    :value="radioValue"
    :options="toggleOptions"
    :disabled="isLoading"
    @selected="update"
  />
</template>
<script>
  import RadioToggle from 'components/common/RadioToggle'
  import Util from 'lib/util'
  import { updateEmployee } from 'services/EmployeeService'

  const TOGGLE_VALUES = {
    1: true,
    0: false,
  }

  export default {
    components: {
      RadioToggle,
    },
    props: {
      employee: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
        localSkipEverify: this.employee.skip_everify,
        toggleOptions: [
          {label: 'On', value: '1'},
          {label: 'Off', value: '0'},
        ],
      }
    },
    computed: {
      radioValue () {
        return this.localSkipEverify ? '1' : '0'
      },
    },
    methods: {
      async update (value) {
        this.isLoading = true
        return updateEmployee(this.employee.id, {skip_everify: TOGGLE_VALUES[value]})
          .then(() => {
            this.localSkipEverify = TOGGLE_VALUES[value]
            Util.showFlashNotice('Skip E-Verify has been updated.')
          }).catch((xhr) => {
            Util.ajaxErrorDialog(`An error accurred updating the Skip E-Verify preference.`, xhr)
          }).finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>
