import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function updateEmployee (id, attrs) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      url: getRoute('employee_path', {id}),
      data: {
        employee: attrs,
      },
    })
      .done(res => resolve(res))
      .fail(xhr => reject(xhr))
  })
}
