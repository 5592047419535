import BulkCountersignCollectionView from 'views/i9/bulk_countersign_collection_view'
import SignaturePadView from 'views/common/forms/signature_pad_view'
import RemoteFormValidation from 'lib/remote_form_validation'
import Routes from 'lib/routes'
import FormsUtil from 'lib/util/forms'
import Util from 'lib/util'
import Constants from 'lib/constants'
import WizardHistory from 'lib/wizard_history'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'
import EmployeeHelper from 'lib/employee'
import SystemEvents from 'lib/system_events'
import AdminI9CountersignEvent from 'models/system_events/admin_i9_countersign_event'

// This view encapsulates both the Review step (reviewing submissions in bulk and adjusting dates) and the Certify
// step. The Certify step's form and everything else are encapsulated locally here, while the Review step behavior
// exists in BulkCountersignCollectionView. The reason for this was to avoid having to re-render the bulk collection
// of I-9's, the dates, etc. if the user goes Back to the Review step from the Certify step. This is not an example of
// ideal wizard state management.
//
// model - I9EmployerReviewForm
export default Marionette.LayoutView.extend({
  // Simple state management
  REVIEW_STEP: 0,
  CERTIFY_STEP: 1,

  template: false,

  ui: {
    reviewTabLink: "#review-tab-link",
    certifyTabLink: "#certify-tab-link",
    reviewTab: "#review",
    certifyTab: "#certify",
    prevButton: '.btn-wizard-prev',
    nextButton: ".btn-wizard-next",
    signaturePad: '#certify .integrated-signature-pad',
    certifyForm: '#certify form',

    everifyNotSubmitted: '#everify-not-submitted',
    everifyReceiptDoc: '#everify-receipt-doc',
    everifyReceiptDocList: '#everify-receipt-doc ul',
    everifySkip: '#everify-skip',
    everifySkipList: '#everify-skip ul',
    everifySubmittable: '#everify-submittable',
    everifySubmittingBody: '#everify-submitting .panel-body',
    everifySubmittingList: '#everify-submitting ul',
    everifySubmitting: '#everify-submitting',
    everifyOpenCase: '#everify-open-case',
    everifyOpenCaseList: '#everify-open-case ul',
    saveWithoutSign: '#save-without-sign',
  },

  events: {
    'click @ui.prevButton': 'back',
    'click @ui.nextButton': 'nextButtonClick',
    keypress: 'keypress',
    'click @ui.saveWithoutSign': 'handleSaveWithoutSign',
  },

  bindings: {
    '[name="i9_employer_review[employer_first_name]"]': 'employer_first_name',
    '[name="i9_employer_review[employer_last_name]"]': 'employer_last_name',
    '[name="i9_employer_review[employer_title]"]': 'employer_title',
    '[name="i9_employer_review[business_name]"]': 'business_name',
    '[name="i9_employer_review[business_street]"]': 'business_street',
    '[name="i9_employer_review[business_city]"]': 'business_city',
    '[name="i9_employer_review[business_state]"]': 'business_state',
    '[name="i9_employer_review[business_zip]"]': 'business_zip',
    '[id="used_alt_procedure"]': 'used_alt_procedure',
    '[name="i9_employer_review[everify_account_id]"]': 'everify_account_id',
  },

  initialize: function() {
    this.documentationChoices = this.getOption('documentationChoices')

    this._history = new WizardHistory(
      function(){return this._currentStep}.bind(this),
      this.prevButtonClick.bind(this),
      this.$el)
  },

  back: function() {
    this._history.back()
  },

  keypress: function(event){
    if (event.keyCode == Constants.KeyCode.KEY_RETURN) {
      this.nextButtonClick(event)
      event.stopPropagation()
      event.preventDefault()
    }
  },

  onRender: function() {
    this.stickit()

    this._bulkCountersignCollectionView = new BulkCountersignCollectionView({
      el: this.ui.reviewTab[0],
      model: this.model,
      collection: this.model.get('submissions'),
      documentationChoices: this.documentationChoices,
    })

    this._bulkCountersignCollectionView.render()

    this._currentStep = this.REVIEW_STEP
    this._showReviewTab()

    this.ui.certifyTabLink.on('shown.bs.tab', this._initializeSignaturePadView.bind(this))

    new RemoteFormValidation({
      path: Routes.onboarding_i9_sign_path,
      resourceName: 'i9_employer_review',
      model: this.model,
      formEl: this.ui.certifyForm,
      partialValidation: false,
      validations: {
        'i9_employer_review[employer_first_name]': { validators: { wbRemote: {} } },
        'i9_employer_review[employer_last_name]': { validators: { wbRemote: {} } },
        'i9_employer_review[employer_title]': { validators: { wbRemote: {} } },
        'i9_employer_review[business_name]': { validators: { wbRemote: {} } },
        'i9_employer_review[business_street]': { validators: { wbRemote: {} }, err: '.street-input-wrapper' },
        'i9_employer_review[business_city]': { validators: { wbRemote: {} } },
        'i9_employer_review[business_state]': { validators: { wbRemote: {} } },
        'i9_employer_review[business_zip]': { validators: { wbRemote: {} } },
        'i9_employer_review[signature_data_uri]': { autoFocus: false, excluded: false, validators: { wbRemote: {} } },
        'i9_employer_review[everify_account_id]': { validators: { wbRemote: {} } },
      },
      successCallback: this.onRemoteValidateSuccess.bind(this),
      failedCallback: this.onRemoteValidateFailed.bind(this),
      errorCallback: this.onRemoteValidateError.bind(this),
    })
  },

  onBeforeDestroy: function() {
    this._history.cleanup()

    if (this._bulkCountersignCollectionView) {
      this._bulkCountersignCollectionView.destroy()
    }

    if (this._signaturePadView) {
      this._signaturePadView.destroy()
    }
  },

  prevButtonClick: function(e) {
    e.preventDefault()
    e.stopPropagation()

    this._currentStep--

    switch (this._currentStep) {
    case this.REVIEW_STEP:
      this._showReviewTab()
      break
    }
  },

  nextButtonClick: function(e) {
    e.preventDefault()
    e.stopPropagation()

    switch (this._currentStep) {
    case this.REVIEW_STEP:
      this._bulkCountersignCollectionView.validate(function(valid) {
        this._stopLadda()
        if (valid){
          this._currentStep++
          this._showCertifyTab()
        }
      }.bind(this))
      break

    case this.CERTIFY_STEP:  // Finish button in this case
      this.ui.certifyForm.submit()
      break
    }
  },

  _showReviewTab: function() {
    this.ui.reviewTabLink.tab('show')
    this.ui.prevButton.addClass('disabled')
    this.ui.prevButton.hide()

    setTimeout(function() {
      this.ui.nextButton.find('.ladda-label').html('Next')
      this.ui.nextButton.find('i.fa').show()
    }.bind(this), 150)
  },

  _showCertifyTab: function() {
    this._history.pushNextStep()

    this.ui.certifyTabLink.tab('show')
    this.ui.prevButton.removeClass('disabled')
    this.ui.prevButton.show()

    this._findEverifiableSubmissions()

    // FIXME: scroll to top

    setTimeout(function() {
      this.ui.nextButton.find('.ladda-label').html('Finish')
      this.ui.nextButton.find('i.fa').hide()
    }.bind(this), 150)
  },

  // HACK: when signaturepad is behind a tab, it can only be initialized once the tab is visible so that it
  // has valid width/height dimensions. We only do this once so that signatures are retained between tab clicks.
  _initializeSignaturePadView: function() {
    if (!this._signaturePadView) {
      this._signaturePadView = new SignaturePadView({ el: this.ui.signaturePad[0], model: this.model })
      this._signaturePadView.render()
    }
  },

  onRemoteValidateSuccess: function(xhr) {
    this._stopLadda()
    FormsUtil.clearFormInvalidNotification(this.ui.certifyForm)
    const systemEvent = new AdminI9CountersignEvent()
    SystemEvents.notify(systemEvent.payload())
    Util.navigateAndShowAjaxFlashNotice(this.model.get('redirect_location'), xhr)
  },

  onRemoteValidateFailed: function() {
    this._stopLadda()
    FormsUtil.showFormInvalidNotification(this.ui.certifyForm)
  },

  onRemoteValidateError: function(xhr) {
    this._stopLadda()
    const xhrDecorated = WBRequestXHRDecorator(xhr)
    if (xhr.status == 409) {
      Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
    }
  },

  _stopLadda: function() {
    this.ui.nextButton.data('ladda').stop()
  },

  // Identify submissions that will/won't be submitted to E-Verify
  _findEverifiableSubmissions: function() {
    // Categories I9s that will/won't be submitted
    const submissionsReceiptDoc = []
    const submissionsSkip = []
    const employeeHasOpenCase = []
    const submissionsEverifiable = []

    this.model.get('submissions').each((submission) => {
      // Contains a receipt document, not certifiable
      if (submission.get('documentation_set').filter(d => d.get('receipt')).length > 0) {
        submissionsReceiptDoc.push(submission)

      // If employee already has an open E-Verify case, don't submit a new one
      } else if (submission.get('employee_has_open_cases')) {
        employeeHasOpenCase.push(submission)

      // If employee has 'skip_everify' set to true, don't submit
      } else if (submission.get('employee_has_skip_everify')) {
        submissionsSkip.push(submission)

      // Good to go!
      } else {
        submissionsEverifiable.push(submission)
      }
    })

    // Only show the necessary panels
    this.ui.everifyNotSubmitted.toggle(submissionsReceiptDoc.length > 0 || employeeHasOpenCase.length > 0 || submissionsSkip.length > 0)
    this.ui.everifySubmitting.toggle(submissionsEverifiable.length > 0)
    this.ui.everifyReceiptDoc.toggle(submissionsReceiptDoc.length > 0)
    this.ui.everifySkip.toggle(submissionsSkip.length > 0)
    this.ui.everifyOpenCase.toggle(employeeHasOpenCase.length > 0)
    this.ui.everifySubmittable.toggle(submissionsEverifiable.length > 0)

    // Function for appending name list elements
    const buildList = (list, submissions) => {
      list.html('')
      submissions.forEach((submission) => {
        const name = EmployeeHelper.fullName(submission)
        list.append($(`<li><strong>${_.escape(name)}</strong></li>`))
      })
    }

    // Build the list of names
    buildList(this.ui.everifyReceiptDocList, submissionsReceiptDoc)
    buildList(this.ui.everifySkipList, submissionsSkip)
    buildList(this.ui.everifySubmittingList, submissionsEverifiable)
    buildList(this.ui.everifyOpenCaseList, employeeHasOpenCase)

    // Create list of submission IDs that can be sent to E-Verify
    this.everifiableSubmissionIds = submissionsEverifiable.map(sub => sub.get('id'))
  },
  getUpdatedStartDateModels() {
    const models = this.model.get('submissions').models || []
    return models.filter(model => !model.hasOriginalEmployeeStartDate())
  },
  saveSubmissionData(changedSubmissions) {
    $.ajax({
      method: 'POST',
      url: Routes.onboarding_i9_update_submissions_path,
      dataType: 'json',
      data: {
        submissions: JSON.stringify(changedSubmissions),
      },
    }).success((_data, _status, xhr) => {
      this.ui.saveWithoutSign.data('ladda').stop()
      Util.navigateAndShowAjaxFlashNotice(Routes.onboarding_i9_index_path, xhr)
    }).fail(xhr => {
      this.ui.saveWithoutSign.data('ladda').stop()
      Util.ajaxErrorDialog('An error occurred, please try again.', xhr)
    })
  },

  handleSaveWithoutSign() {
    this._bulkCountersignCollectionView.validate(valid => {
      this.ui.saveWithoutSign.data('ladda').stop()
      if (valid){
        const changedSubmissions = this.getUpdatedStartDateModels()
        if (changedSubmissions.length) {
          this.saveSubmissionData(changedSubmissions)
        } else {
          window.location.href = Routes.onboarding_i9_index_path
        }
      }
    })
  },
})
